.main-container-logged {
    width: 100%;
    margin-left: 280px;

    .hamburger-menu-container {
        text-align: right;
        text-align: -webkit-right;
    }

    .main-content-logged {
        min-height: calc(100vh - 4.5rem);
    }
}

.main-container-logged-out {
    display: flex;
    align-items: center;
    width: 100%;
}

@media (max-width: 768px) {
    .main-container-logged {
        margin-left: 0;

        .main-content-logged {
            min-height: calc(100vh - 8.5rem);

            .statistic-player-container-time-frame {
                margin-top: 1.5rem;

                .statistic-player-dates {
                    flex-direction: column;
                    white-space: pre-line;
                    line-height: .5rem;
                }
            }
        }
    }
}

.toast {
    display: block !important;
    max-height: 0;
    opacity: 0;
    transition: all ease .5s;
}

.show-toast {
    opacity: 1;
    max-height: unset;
}

// Newsletter
.popup-wrapper {
   display: none;
   background: rgba(0, 0, 0, 0.5);
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   z-index: 999
}

.popup {
    font-family: arial;
    text-align: center;
    width: 100%;
    max-width: 300px;
    margin: 10% auto;
    padding: 20px;
    background: #fff;
    position: relative;
}

.popup a {
    color: #fff;
    cursor: pointer;
    padding: 6px 10px;
    text-decoration: none;
}

.popup-close {
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
    background-color: red;
    width: 15px;
    color: #fff;
}

#qr img {
    margin: 0 auto;
}
